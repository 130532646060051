import { useContext, useEffect, useMemo, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useRouter } from 'next/router'
import Link from 'next/link'
import Image from 'next/image'
import styles from './styles.module.scss'
import { FormHookProvider } from '@/components/moleculas'
import { InputText } from '@/components/moleculas/InputText'
import { InputPassword } from '@/components/moleculas/InputPassword'
import { RadioButton } from '@/components/moleculas/RadioButton'
import { Button } from '@/components/atoms'
import logoBlack from '@/img/logoBlack.png'
import { AuthContext } from '@/context/AuthContext'
import IconWithUnderline from '@/components/moleculas/IconWithUnderline/IconWithUnderline'

export const Login = () => {
  const { onSubmit, isLoading } = useContext(AuthContext)
  const [isEmailInput, setIsEmailInput] = useState(true)

  const methods = useForm()
  const router = useRouter()

  useEffect(() => {
    if (!isEmailInput) {
      methods.setValue('email', '')
    } else {
      methods.setValue('phone_number', '')
    }
  }, [isEmailInput])

  const inputTextEmail = useMemo(
    () => ({
      name: 'email',
      type: 'email',
      placeholder: 'Ingrese correo',
      label: 'Correo',
      icon: 'icon i-user',
      variant: 'primary',
      height: '45px',
      rules: {
        required: 'El correo electrónico es requerido.',
        pattern: {
          value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
          message: 'Ingresa un correo electrónico válido.'
        }
      }
    }), [])

  const inputPassword = useMemo(() => ({
    name: 'password',
    placeholder: 'Ingrese contraseña',
    label: 'Contraseña',
    feedback: false,
    toggleMask: true,
    rules: {
      required: 'La contraseña es requerida.',
      minLength: {
        value: 6,
        message: 'La contraseña debe tener al menos 6 caracteres.'
      }
    },
    className: 'input-bold password-icon'
  }), [])

  const inputPhonenumber = useMemo(() => ({
    name: 'phone_number',
    type: 'number',
    placeholder: 'Ingrese número',
    label: 'Teléfono',
    variant: 'primary',
    height: '45px',
    rules: {
      required: 'El correo electrónico es requerido.',
      pattern: {
        value: /^\d{10}$/i,
        message: 'Ingresa un correo electrónico válido.'
      }
    }
  }), [])

  return (
    <div className={styles.section}>
      <div className={styles.section_container}>
        <div className={styles.section_header}>
          <Link href='/'>
            <Image
              width={500}
              height={500}
              alt='general-configuration'
              src={logoBlack}
              className={styles.image}
              loading='lazy'
            />
          </Link>
        </div>
        <FormHookProvider methods={methods} method='POST' onSubmit={onSubmit.handleLoginSubmit}>
          <div className={styles.iconsSection}>
            <span onClick={() => setIsEmailInput(true)}>
              <IconWithUnderline
                src='/img/icons/arrobaIcon.svg' width={150} height={150}
                alt='arroba-icon'
              />
            </span>
            <span onClick={() => setIsEmailInput(false)}>
              <IconWithUnderline
                src='/img/icons/phoneIcon.svg' width={150} height={150}
                alt='phone-icon'
              />
            </span>
          </div>
          {
            isEmailInput
              ? (
                <div className={styles.form}>
                  <div className={styles.form_input}>
                    <InputText {...inputTextEmail} />
                  </div>
                  <div className={styles.form_input}>
                    <InputPassword {...inputPassword} />
                  </div>
                  <div className={styles.form_remember}>
                    <RadioButton
                      name='remember'
                      value='remember'
                      label='Recordarme'
                      variant='secondary'
                    />
                    <Link href='/login/recover-password'>Olvidé mi contraseña</Link>
                  </div>
                  <div className={styles.form_button}>
                    <Button
                      label='Iniciar sesión'
                      type='submit'
                      rounded
                      className='btn-yellow btn-md'
                      loading={isLoading}
                    />
                  </div>
                </div>
                )
              : (
                <div className={styles.form}>
                  <div className={styles.form_input}>
                    <InputText {...inputPhonenumber} />
                  </div>
                  <div className={styles.form_input}>
                    <InputPassword {...inputPassword} />
                  </div>
                  <div className={styles.form_remember}>
                    <RadioButton
                      name='remember'
                      value='remember'
                      label='Recordarme'
                      variant='secondary'
                    />
                    <Link href='/login/recover-password'>Olvidé mi contraseña</Link>
                  </div>
                  <div className={styles.form_button}>
                    <Button
                      label='Iniciar sesión'
                      type='submit'
                      rounded
                      className='btn-yellow btn-md'
                      loading={isLoading}
                    />
                  </div>
                </div>
                )
          }
          {/* <div className={styles.form_button}>
            <div>
              <Button className='btn-black btn-md' onClick={() => router.push('/login')} label='Volver' type='button' />
            </div>
            <div>
              <Button className='btn-yellow btn-md' type='submit' label='Enviar código' />
            </div>
          </div> */}
        </FormHookProvider>
        <div className={styles.section_footer}>
          <div>¿Aún no tienes cuenta?</div>
          <div>
            <Button
              label='Registrarme'
              onClick={() => router.push('/register')}
              rounded
              className='btn-yellow btn-md'
            />
          </div>
        </div>
      </div>
    </div>
  )
}
