import { RadioButton as RadioButtonAtom } from '@/components/atoms/RadioButton'
import { useState } from 'react'
import styles from './styles.module.scss'
import PropTypes from 'prop-types'

export const RadioButton = ({
  inputId,
  name,
  value,
  defaultValue,
  label,
  variant,
  rules,
  disabled,
  className
}) => {
  const [checked, setChecked] = useState(false)

  return (
    <div className={`${styles.radiobutton} ${styles[variant]} ${checked ? styles.radiobutton_checked : ''} ${className}`}>
      <RadioButtonAtom
        inputId={inputId}
        name={name}
        value={value}
        defaultValue={defaultValue}
        onChecked={(checked) => setChecked(checked)}
        rules={rules}
        disabled={disabled}
        invalidClassName={styles.invalid}
      />
      {label && <label htmlFor={inputId}>{label}</label>}
    </div>
  )
}

RadioButton.defaultProps = {
  variant: 'primary',
  required: false,
  disabled: false,
  className: ''
}

RadioButton.propTypes = {
  name: PropTypes.string,
  variant: PropTypes.string,
  required: PropTypes.bool,
  disabled: PropTypes.bool,
  className: PropTypes.string
}
