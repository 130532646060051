import { RadioButton as RadioButtonPrime } from 'primereact/radiobutton'
import { useController } from 'react-hook-form'
import PropTypes from 'prop-types'
import { useEffect } from 'react'

export const RadioButton = ({
  inputId,
  name,
  value,
  defaultValue,
  onChecked,
  rules,
  disabled,
  invalidClassName,
  className
}) => {
  const { field, fieldState: { error } } = useController({
    name,
    rules,
    defaultValue
  })

  useEffect(() => {
    onChecked(field.value === value)
  }, [field.value])

  return (
    <RadioButtonPrime
      inputId={inputId}
      name={name}
      disabled={disabled}
      className={`${className} ${error && invalidClassName}`}
      {...field}
      inputRef={field.ref}
      value={value}
      checked={field.value === value}
    />
  )
}

RadioButton.defaultProps = {
  inputId: '',
  name: '',
  value: '',
  defaultValue: '',
  onChecked: () => { },
  disabled: false,
  invalidClassName: '',
  className: ''
}

RadioButton.propTypes = {
  inputId: PropTypes.string,
  name: PropTypes.string,
  disabled: PropTypes.bool,
  invalidClassName: PropTypes.string,
  className: PropTypes.string
}
